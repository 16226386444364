import React, { useState, useEffect, useCallback } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './App.css'

const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://meateasy.onrender.com'

const App = () => {
  const [data, setData] = useState({ message: '...' })

  const getData = useCallback(async () => {
    try {
      const res = await fetch(`${API_BASE_URL}/api/v1/health-check`)
      const json = await res.json()
      setData(json)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <header className="App-header">
          <>
            <h1>{data.message}</h1>
            {data.message !== '...' && (
              <>
                <p>🥩 Connected to backend service 🥩</p>
                <Link to="/order">Order</Link>
              </>
            )}
          </>
        </header>
      ),
    },
    {
      path: '/order',
      element: (
        <div className="App-page">
          <div className="App-container">
            <h1>Order</h1>
            <h2>CUT OPTIONS</h2>
            <ul>
              <li>2 Blade Steaks and Roasts OR Roasts OR Ground Beef</li>
              <li>Prime Rib Roast OR Rib Steaks</li>
              <li>
                T-Bone(first 4 are porter houses) OR NY Strip Steak and
                Tenderloin
                <ul>
                  <li>
                    IF NY Strip and Tenderloin, THEN Whole Tenderloin OR Filet
                    Mignon
                  </li>
                </ul>
              </li>
              <li>
                How do you want Rounds cut? Roasts OR Steaks?
                <ul>
                  <li>
                    IF Steaks, THEN Round Steaks(not tenderized) OR Cube
                    Steaks(tenderized)
                  </li>
                </ul>
              </li>
              <li>Short Ribs? Y OR N</li>
              <li>Brisket? Y OR N (no=more ground beef)</li>
              <li>
                Carne Asada? Y OR N (If yes, it may decrease number of steaks
                cut)
              </li>
              <li>
                Skirt Steak? Y OR N (no=more ground beef or possibly carne
                asada)
              </li>
              <li>
                Flank Steak? Y OR N (no=more ground beef or possibly carne
                asada)
              </li>
              <li>
                Sirloin Steak? Y OR N (no=more ground beef or possibly carne
                asada)
              </li>
              <li>
                Picanha Steak (Sirloin tip with fat cap still attached)? Y OR N
                <ul>
                  <li>IF 11=N AND 10=Y THEN will be cut as sirloin steak</li>
                </ul>
              </li>
              <li>Stew Meat? Y OR N</li>
              <li>Osso Bucco? Y OR N</li>
            </ul>
            <h2>WRAP OPTIONS</h2>
            <ul>
              <li>How many steaks/package? 2 / 3 / 4</li>
              <li>Thickness of steaks? ¾” / 1” / 1 ½”</li>
              <li>Pounds/Roast? 2-3 / 3-4 / 4-5</li>
              <li>Pounds Ground Beef/package? 1 / 1 ½ / 2</li>
            </ul>
          </div>
        </div>
      ),
    },
  ])

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  )
}

export default App
